import React from 'react';
import './RoundedContainer.css';

function RoundedContainer({ height, width, children, style }) {
  // Calculate border radius based on provided height and width
  const borderRadius =
    height && width ? Math.min(height, width) / 10 : 10; // Fallback to 10px if not provided

  // Set CSS variables for dynamic values
  const dynamicStyles = {
    '--container-height': height ? `${height}px` : 'auto',
    '--container-width': width ? `${width}px` : 'auto',
    '--container-radius': `${borderRadius}px`,
  };

  return (
    <div className="rounded-container" style={{ ...dynamicStyles, ...style }}>
      {children}
    </div>
  );
}

export default RoundedContainer;
