import React from 'react';
import RoundedContainer from './RoundedContainer';
import LPMFitnessLogo from '../assets/LPMFitness.png';
import './Containers.css';
//import AppStoreButton from './AppStoreButton/AppStoreButton';

function LPMFitnessContainer() {
    const height = 275;
    const width = 750;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={LPMFitnessLogo} alt="LPMFitness Logo" className="AppLogo" />
                    <span className="AppTitle">LPM Fitness Chicago</span>
                </div>
                <div className="Description">
                    <p>LPM Fitness Chicago connects trainers and clients seamlessly. Trainers update workouts and plans, instantly visible to clients. Secure Google login, cloud-based access, and a robust database power this full-stack fitness app.</p>
                </div>
                <div className="Spacing"></div>
            </div>
        </RoundedContainer>
    );
}

export default LPMFitnessContainer;
