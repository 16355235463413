// src/pages/LandingPage.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './LandingPage.css';
import project1Image from '../assets/project1.png';
import project2Image from '../assets/project2.png';
import project3Image from '../assets/project3.jpg';
import project4Image from '../assets/project4.png';
import project5Image from '../assets/project5.png';
import project6Image from '../assets/project6.png';
import project7Image from '../assets/project7.png';

import HeroSection from './LandPageComponents/HeroSection';

function LandingPage({ headerHeight }) {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const projects = [
    { img: project1Image, title: 'RiffTune' },
    { img: project2Image, title: 'LPM Fitness Chicago' },
    { img: project3Image, title: 'Vision Solutions AR' },
    { img: project4Image, title: 'Campus Maps PSU' },
    { img: project5Image, title: 'Lion Spell' },
    { img: project6Image, title: 'Pokedex' },
    { img: project7Image, title: 'Pentominoes (iPad)' },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const SERVICE_ID = 'service_y0pyp3h';
    const TEMPLATE_ID = 'template_bylnznh';
    const PUBLIC_KEY = 'q-NIkrhPFwPDwFOU-';

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_KEY)
      .then((result) => {
        console.log('Email sent:', result.text);
        setStatus('Message sent successfully!');
        setFormData({ from_name: '', from_email: '', message: '' });
      }, (error) => {
        console.error('Email sending error:', error.text);
        setStatus('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className="Content" style={{ paddingTop: `${headerHeight}px` }}>
      {/* Hero Section */}

      <HeroSection />

      {/* Skills & Experience Section */}
      <section className="skills-experience-section">
        <h2>Education & Skills</h2>
        <h3>Education</h3>
        <p><strong>B.S. Computer Science</strong> - The Pennsylvania State University at University Park, PA</p>

        <h3>Coursework</h3>
        <div className="coursework-list">
          <p><strong>AI and Machine Learning</strong></p>
          <p><strong>Computer Organization and Design</strong></p>
          <p><strong>Data Structure and Algorithms</strong></p>
          <p><strong>SQL Database Management</strong></p>
          <p><strong>Application Programming w/ Swift</strong></p>
          <p><strong>Communication Networks</strong></p>
        </div>

        <h3>Skills</h3>
        <div className="skills-list">
          <h4>Web Development</h4>
          <p>React, Next.js, .NET Core, & JavaScript</p>

          <h4>Programming Experience</h4>
          <p>Python, Java, Swift, C#, C++, and others</p>

          <h4>Project Management & Organization</h4>
          <p>Unity, XCode, Jira, Atlassian</p>

          <h4>Database & Cloud</h4>
          <p>MongoDB, AWS, Cloudflare, & Unity Cloud</p>
        </div>
      </section>

      {/* My Resume Button */}
      <section className="projects-overview">
        <h2>My Resume</h2>
        <h3> </h3>
        <p>
          <button className="projects-button" onClick={() => window.location.href = '/resume'}>
            Check out my Resume
          </button>
        </p>
      </section>

      {/* Projects Overview */}
      <section className="projects-overview">
        <h2>My Projects</h2>
        <p>
          <button className="projects-button" onClick={() => window.location.href = '/projects'}>
            Check out my Projects
          </button>
        </p>

        {/* Horizontal Scrolling Project Samples */}
        <div className="project-scroll-container">
          <div className="project-samples scrolling-content">
            {projects.map((project, index) => (
              <div className="project-sample" key={index}>
                <img src={project.img} alt={`Project Sample ${index + 1}`} />
                <p className="project-title">{project.title}</p>
              </div>
            ))}
            {/* Duplicate set for infinite scroll effect */}
            {projects.map((project, index) => (
              <div className="project-sample" key={`dup-${index}`}>
                <img src={project.img} alt={`Project Sample ${index + 1}`} />
                <p className="project-title">{project.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Me Section */}
      <section className="contact-section">
        <h2>Contact Me</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="from_name">Name</label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              required
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="from_email">Email</label>
            <input
              type="email"
              id="from_email"
              name="from_email"
              value={formData.from_email}
              onChange={handleChange}
              required
              placeholder="your.email@example.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
              placeholder="Your message..."
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
        {status && (
          <p
            className={`status-message ${status.includes('successfully') ? 'status-success' : 'status-error'}`}
          >
            {status}
          </p>
        )}
      </section>
    </div>
  );
}

export default LandingPage;
