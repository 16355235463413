import React from 'react';
import RoundedContainer from './RoundedContainer';
import RiffTuneLogo from '../assets/RiffTune.png';
import './Containers.css';
import AppStoreButton from './AppStoreButton/AppStoreButton';

function RiffTuneContainer() {
    const height = 325;
    const width = 750;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={RiffTuneLogo} alt="RiffTune Logo" className="AppLogo" />
                    <span className="AppTitle">RiffTune</span>
                </div>
                <div className="Description">
                    <p>RiffTune is a stylized iOS app for guitarists, offering custom tunings and a versatile metronome with any BPM or time signature. Perfect for crafting your unique sound and staying in rhythm.</p>
                </div>

                <div className="Spacing"></div>

                <AppStoreButton 
                        link="https://apps.apple.com/fi/app/rifftune/id6499281780" 
                        text="Download on the App Store" 
                    />
            </div>
        </RoundedContainer>
    );
}

export default RiffTuneContainer;
