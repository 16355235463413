import React from 'react';
import ProfilePng from '../../assets/HeroSectionPhoto.png';
import './HeroSection.css'; // Ensure this file is in the same folder

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div 
        className="hero-content" 
        style={{ backgroundImage: `url(${ProfilePng})` }}
      >
      </div>
    </section>
  );
};

export default HeroSection;
