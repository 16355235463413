import React from 'react';
import RoundedContainer from './RoundedContainer';
import CarStruckLogo from '../assets/CarStruckAR.png';
import './Containers.css';
import AppStoreButton from './AppStoreButton/AppStoreButton';

function CarStruckContainer() {
    const height = 375;
    const width = 750;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={CarStruckLogo} alt="Car Struck AR Logo" className="AppLogo" />
                    <span className="AppTitle">Car Struck AR</span>
                </div>
                <div className="Description">
                    <p>Car Struck AR is an augmented reality racing app where you can race in endless runner mode to earn coins, unlock and customize new vehicles, and drive them in AR for an immersive experience.</p>
                </div>
                <div className="Spacing"></div> {/* Add spacing between description and bottom sections */}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px' }}>
                    <AppStoreButton 
                        link="https://apps.apple.com/us/app/car-struck-ar/id6450318214" 
                        text="Download on the App Store" 
                    />
                    <AppStoreButton 
                        link="https://play.google.com/store/apps/details?id=com.VisionSolutionsAR.CarStruckAR&hl=en_US" 
                        text="Download on the Play Store" 
                    />
                </div>
            </div>
        </RoundedContainer>
    );
}

export default CarStruckContainer;
