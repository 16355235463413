import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import ProfileCircle from './assets/ProfilePicture.png';
import GitHubLogo from './assets/github.png';
import LinkedInLogo from './assets/linkedin.png';

// Import your pages
import LandingPage from './pages/LandingPage';
import ProjectsPage from './pages/ProjectsPage';
import ResumePage from './pages/ResumePage.js';

function App() {
  const [activeSection, setActiveSection] = useState('');
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const handleSectionClick = (sectionName) => {
    const newActiveSection = activeSection === sectionName ? '' : sectionName;
    setActiveSection(newActiveSection);
  };

  return (
    <Router>
      <div className="background">
        <div className="App">
          <header ref={headerRef} className="App-header">
            <div className="HeaderGroup">
              <img src={ProfileCircle} alt="The Developer" className="ProfileCircle" />
              <div className="LeftSection">
                <h1 className="Name">Elliot Marsico</h1>
                <p className="Title">Software Developer</p>
              </div>
            </div>

            <ul className="RightSection">
              <li className="RightSectionItem" onClick={() => handleSectionClick('')}>
                <Link to="/">Home</Link>
              </li>
              <li className="RightSectionItem" onClick={() => handleSectionClick('projects')}>
                <Link to="/projects">Projects</Link>
              </li>
              <li className="RightSectionItem" onClick={() => handleSectionClick('resume')}>
                <Link to="/resume">Resume</Link>
              </li>
            </ul>

            <div className="IconButtons">
              <a
                href="https://github.com/emarsico"
                target="_blank"
                rel="noopener noreferrer"
                className="IconButton"
              >
                <img src={GitHubLogo} alt="GitHub" />
              </a>
              <a
                href="https://www.linkedin.com/in/emarsico/"
                target="_blank"
                rel="noopener noreferrer"
                className="IconButton"
              >
                <img src={LinkedInLogo} alt="LinkedIn" />
              </a>
            </div>
          </header>

          <Routes>
            <Route path="/" element={<LandingPage headerHeight={headerHeight} />} />
            <Route path="/projects" element={<ProjectsPage headerHeight={headerHeight} />} />
            <Route path="/resume" element={<ResumePage headerHeight={headerHeight} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
