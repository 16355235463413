// src/pages/ProjectsPage.js
import React from 'react';
import NetworkAnimation from '../NetworkAnimation';

// Import your containers
import RiffTuneContainer from '../Containers/RiffTuneContainer';
import CarStruckContainer from '../Containers/CarStruckContainer';
import VisionSolutionsContainer from '../Containers/VisionSolutionsContainer';
import LPMFitnessContainer from '../Containers/LPMFitnessContainer';

function ProjectsPage({ headerHeight }) {
  return (
    <div className="projects-background">
      <NetworkAnimation />
      <div className="Content" style={{ paddingTop: `${headerHeight}px` }}>
        <div className="ScrollContainer">
          <VisionSolutionsContainer />
          <RiffTuneContainer />
          <LPMFitnessContainer />
          <CarStruckContainer />
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
