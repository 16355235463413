import React from 'react';
import './AppStoreButton.css'; // Import the CSS for the button

function AppStoreButton({ link, text }) {
    return (
        <a 
            href={link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="AppStoreButton"
        >
            {text}
        </a>
    );
}

export default AppStoreButton;
