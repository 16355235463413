import React from 'react';
import RoundedContainer from './RoundedContainer';
import VisionSolutionsLogo from '../assets/vsar.png';
import './Containers.css';
import AppStoreButton from './AppStoreButton/AppStoreButton';

function VisionSolutionsContainer() {
    const height = 400;
    const width = 750;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={VisionSolutionsLogo} alt="Vision Solutions AR Logo" className="AppLogo" />
                    <span className="AppTitle">Vision Solutions AR - Lead Software Developer</span>
                </div>
                <div className="Description">
                    <p>Museum Vision (Vision Solutions AR) is an AR management platform for museums, enabling the placement of videos, avatars, 3D models, and more in augmented reality. Trusted by Mystic Aquarium, Explore York, A.K. Smiley Library, The Columns Museum, and The Hindenburg Museum.</p>
                </div>
                <div className="Spacing"></div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '25px' }}>
                    <AppStoreButton 
                        link="https://apps.apple.com/us/app/vision-solutions-ar/id6446692461" 
                        text="Download on the App Store" 
                    />
                    <AppStoreButton 
                        link="https://play.google.com/store/apps/details?id=com.visionsolutions.visionsolutionsar&hl=en_US&pli=1" 
                        text="Download on the Play Store" 
                    />
                </div>
            </div>
        </RoundedContainer>
    );
}

export default VisionSolutionsContainer;