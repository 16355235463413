import React from 'react';

const ResumePage = ({ headerHeight }) => {
  // Path to your PDF file placed in the public folder
  const pdfUrl = '/resume.pdf';

  // Calculate height for the viewer based on header height
  const viewerStyle = {
    width: '100%',
    height: `calc(100vh - ${headerHeight}px)`,
    border: 'none'
  };

  return (
    <div style={{ marginTop: headerHeight }}>
      <iframe 
        src={pdfUrl} 
        title="Resume" 
        style={viewerStyle} 
      />
    </div>
  );
};

export default ResumePage;
